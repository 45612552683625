.dataWrapper {
  border: 1px solid var(--border-2);
  border-radius: 0.4rem;
  thead {
    tr {
      th {
        background: var(--table-header) !important;
        border-bottom: none;
        padding: 1rem 1.2rem;
        color: var(--text-1);
        font-size: 1.3rem;
        vertical-align: middle;
        font-weight: 600;
        // text-transform: uppercase;
      }
      &::before {
        content: none;
      }
    }
    .displayName {
      color: var(--text-1);
      text-transform: capitalize;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: var(--odd-tr);
      }
      td {
        // background:var(--table-body);
        vertical-align: middle;
        border-bottom: none;
        padding: 0.9rem 1.2rem;
        font-size: 1.3rem;
        color: var(--td-color);
        vertical-align: baseline;
      }
      &:hover {
        td {
          // background:var(--table-row-active) !important;
          // cursor: pointer;
        }
      }
      .text {
        color: var(--td-color);
        // font-size: 1.2rem;
        // font-weight: 600;
      }
    }
  }
  .linkWrapper a {
    font-size: 1.2rem !important;
  }
  .paginationWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 2rem;
    .entries {
      justify-items: left;
      display: grid;
      align-items: center;
      span {
        color: var(--td-color);
      }
    }
    ul {
      justify-self: right;
      li {
        min-width: 25px;
        height: 25px;
        svg {
          width: 1rem;
          height: 2rem;
        }
        a {
          height: 100%;
          display: grid;
          align-items: center;
          font-size: 1.2rem;
        }
        span {
          color: var(--menu-color);
        }
      }
    }
  }
  .footer {
    display: grid;
    justify-content: right;
    align-items: center;
    background: var(--table-header);
    padding: 1rem;
    .addIcon {
      background: var(--text-11);
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 0.3rem;
      align-items: center;
      justify-content: center;
      display: grid;
    }
  }
}

.editDataWrapper {
  border: 1px solid var(--border-2);
  border-radius: 0.4rem;
  thead {
    tr {
      th {
        background: var(--table-header);
        border-bottom: none;
        padding: 1rem 1.2rem;
        color: var(--th-color);
        font-size: 1.2rem;
        vertical-align: middle;
        text-transform: uppercase;
      }
      &::before {
        content: none;
      }
    }
    .displayName {
      color: var(--text-1);
      text-transform: capitalize;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: var(--odd-tr);
      }
      td {
        // background:var(--table-body);
        vertical-align: middle;
        border-bottom: none;
        padding: 0.9rem 1.2rem;
        font-size: 1.2rem;
        color: var(--td-color);
        .text {
          height: 3rem;
          display: grid;
          align-items: center;
        }
      }
      &:hover {
        td {
          // background:var(--table-row-active) !important;
          cursor: pointer;
        }
      }
      .text {
        color: var(--text-1);
        font-size: 1.2rem;
      }
    }
  }
  .paginationWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 2rem;
    .entries {
      justify-items: left;
      display: grid;
      align-items: center;
      span {
        color: var(--td-color);
      }
    }
    ul {
      justify-self: right;
      li {
        min-width: 25px;
        height: 25px;
        svg {
          width: 1rem;
          height: 2rem;
        }
        a {
          height: 100%;
          display: grid;
          align-items: center;
          font-size: 1.2rem;
        }
        span {
          color: var(--menu-color);
        }
      }
    }
  }
  .footer {
    display: grid;
    justify-content: right;
    align-items: center;
    background: var(--table-header);
    padding: 1rem 1.6rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    grid-auto-flow: column;
    grid-gap: 1rem;
    .addIcon {
      background: var(--text-11);
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 0.3rem;
      align-items: center;
      justify-content: center;
      display: grid;
      svg {
        fill: var(--white);
      }
      &:hover {
        background: var(--primary);
        cursor: pointer;
      }
    }

    .trash {
      padding: 0.2rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      border-radius: 0.3rem;
      align-items: center;
      justify-content: center;
      display: grid;
    }
  }
  .checkbox {
    display: grid;
    justify-content: center;
    align-items: center;
  }
}

.whiteSpace{
  white-space: break-spaces;
}