.wrapper {
  border-radius: 0.4rem;
  width: max-content;
  padding: 0.1rem 1rem;
  font-size: 1.3rem;
  background: rgba(79, 104, 173, 0.1);
  color: var(--text-9);
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
}

.link {
  color: var(--link);
}

.common {
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
  gap: 1rem;
}

.title {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;

  span {
    color: var(--text-1);
    font-weight: 600 !important;
  }
}

.message {
  padding: 2rem;
}

._Success,
._success,
._Passed,
._Active,
._Enable,
._Create {
  background: rgba(4, 174, 93, 0.12);
  color: var(--success);
}

._200 {
  background: rgba(4, 174, 93, 0.12);
  color: var(--success);
}

._201,
._inprogress {
  background: rgba(255, 111, 50, 0.12);
  color: var(--warning);
}

._204 {
  background: rgba(48, 68, 124, 0.6);
  color: var(--text-9);
}

._Failed,
._failed,
._DeActivate,
._Rejected {
  background: rgba(255, 37, 76, 0.1);
  color: var(--error);
}

._error,
._Error,
._Expired {
  background: rgba(255, 37, 76, 0.1);
  color: var(--error);
}

._Disable {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}

._default {
  background: rgba(4, 174, 93, 0.12);
  color: var(--success);
}