.logo {
    display: grid;
    align-items: center;
    justify-items: left;

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
}

.menu {
    display: grid;
    align-items: center;
    justify-items: right;
    grid-template-columns: 1fr max-content;
    gap: 20px;

    @media only screen and (max-width: 600px) {
        display: none !important;
    }

}

.header {
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 10rem 1fr;

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        justify-content: center;
    }
}

.sitelayout {
    padding: 3rem 5rem;

    @media only screen and (max-width: 600px) {
        padding: 2rem 1rem;
    }
}

.dataTable {
    padding-bottom: 5rem;
    h4{
        padding: 1rem;
        border-radius: 0.4rem;
        font-size: 1.8rem;
        font-weight: bold;
    }
    @media only screen and (max-width: 600px) {
        display: none !important;
    }
}

.dataTableMobile {
    padding: 5rem 0;

    @media only screen and (min-width: 600px) {
        display: none !important;
    }
}

.search {
    padding: 10px 0;

    .label {
        padding-bottom: 10px;
    }
}

.footer {
    display: grid;
    grid-template-rows: 1fr 6rem;

    .items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        color: #d2d2d2;
        padding: 20px;
        font-size: 14px;
        text-align: left;

        @media only screen and (max-width: 600px) {
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: unset;
        }

        p {

            line-height: 1.7;
            padding-top: 15px;
        }

        h6 {
            font-size: 18px;
            margin: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            font-weight: 300;
        }
    }

    .contact {
        padding-bottom: 20px;

        ul {
            list-style: none;
            padding: 0;
            display: grid;
            gap: 10px;
            padding-top: 15px;

            li {
                display: grid;
                grid-template-columns: 20px 1fr;
                justify-items: left;
                gap: 15px;
                cursor: pointer;

                a {
                    color: #d2d2d2;
                }
            }
        }
    }

    .follow {
        ul {
            list-style: none;
            padding: 0;
            display: grid;
            padding-top: 15px;
            grid-auto-flow: column;
            gap: 15px;
            justify-content: left;

            li {
                display: grid;
                grid-template-columns: 20px 1fr;
                justify-items: left;
                cursor: pointer;
                padding: 5px;

                a {
                    color: #d2d2d2;
                    font-size: 23px;
                    cursor: pointer;
                }
            }
        }
    }

    .copy {
        background: #23272A;
        color: #d2d2d2;
        display: grid;
        align-items: center;
    }
}