.wrapper {
  border-radius: 0.4rem;
  width: max-content;
  padding: 0.1rem 1.2rem;
  font-size: 1.3rem;
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}

.link {
  color: var(--link);
}

.common {
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
  gap: 1rem;
}

.title {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;

  span {
    color: var(--text-1);
    font-weight: 600 !important;
  }
}

.message {
  padding: 2rem;
}

._Done,
._Closed {
  background: rgba(4, 174, 93, 0.12);
  color: var(--success);
}

._Ready_For_QA,
._Clarification_Provided,
._Fixed {
  background: rgba(67, 94, 255, 0.12);
  color: var(--link);
}

._Create {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}

._To_Do,
._Open {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}

._Abandoned,
._Blocked {
  background: rgba(255, 37, 76, 0.1);
  color: var(--error);
}

._In_Progress {
  background: rgba(255, 194, 34, 0.12);
  color: var(--warning);
}

._QA_Failed {
  background: rgba(255, 37, 76, 0.1);
  color: var(--error);
}

._READY_FOR_SPRINT {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}

._PO_Review {
  background: rgba(67, 94, 255, 0.12);
  color: var(--link);
}

._Deferred,
._Reopened,
._Clarification_Required {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}