.wrapper {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;

  .name {
    text-transform: capitalize;
  }

  .date {
    font-size: 1.1rem;
    color: var(--text-11);
  }
}

.whiteSpace{
  white-space: break-spaces;
}