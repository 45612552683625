.wrapper {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;

  .name {
    text-transform: capitalize;
  }

  .date {
    font-size: 1.1rem;
    color: var(--text-11);
  }
}

.wrapperCommnets {
  padding: 1rem 0;
}

.flowComments {
  max-height: 50rem;
  overflow: auto;
}

.author {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  justify-content: left;
  align-items: center;

  .circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 1.1rem;
    color: #FFF;
  }

  .a,
  .j,
  .s {
    background: var(--status-completed-dark);
  }

  .b,
  .k,
  .t {
    background: var(--status-blocked-dark);
  }

  .c,
  .l,
  .u {
    background: var(--status-done-dark);
  }

  .d,
  .m,
  .v {
    background: var(--status-duplicate-dark);
  }

  .e,
  .n,
  .w {
    background: var(--status-hold-dark);
  }

  .f,
  .o,
  .x {
    background: var(--status-inprogress-dark);
  }

  .g,
  .p,
  .y {
    background: var(--status-inreview-dark);
  }

  .h,
  .q,
  .z {
    background: var(--status-rejected-dark);
  }

  .i,
  .r {
    background: var(--status-reopen-dark);
  }
}

.authInfo {
  .name {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .date {
    font-size: 1.1rem;
    color: var(--text-11);
  }
}

.comments {
  padding: 1rem 4rem;

  .link {
    color: var(--link);
  }

  .tag {
    background: var(--link);
    color: var(--white);
    padding: .3rem 1rem;
    border-radius: 1rem;
    font-size: 1.3rem;
  }
}

.whiteSpace{
  white-space: break-spaces;
  color: var(--link);
  cursor: pointer;
}