@media screen {
  .wrapper {
    display: grid;
    justify-content: center;
    align-items: center;

    &:hover {
      svg {
        fill: "#23a455";
      }
    }
  }
  .pointer {
    cursor: pointer;
  }
}
