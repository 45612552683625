button:focus {
  outline: 0 !important;
}

html {
  font-size: 10px;
}

.Toastify__toast-body div {
  font-size: 1.4rem;
}

:root {
  --font-family: "Poppins", sans-serif, serif;
  --font-size: 1.4rem;
  --font-thin: 100;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;
  --font-extra-bold: 800;
  --font-black: 900;

  --bg-color: #f1f6f7;
  --bg-1: #f4f7f7;
  --bg-2: #f5f5f5;
  --bg-3: #F3F5FB;
  --bg-4: #888585;
  --bg-5: #F7F4F4;
  --bg-6: #FAFAFA;
  --bg-7: #fdfcfc;
  --bg-8: #f4eaef;
  --bg-9: #f0fbff;
  --bg-10: #eae8e8;
  --bg-11: #D0D0D0;
  --bg-12: #fff;
  --bg-13: #4c4949;
  --bg-14: #F4F2F2;
  --bg-15: #E0DFDF;
  --bg-16: #F4EAEF;
  --bg-17: #FDFAFABA;
  --bg-18: #E2E2E2;

  --primary: #ad4679;
  --top-nav-primary: #001628;
  --btn-primary: #646464;
  --sub-primary: #ebebeb;
  --title-primary: #d7dce2;
  --child-nav-primary: #f8f8f8;
  --story-tr: #f1f9fe;
  --nav-tabs-card-bg: #deecf5;
  --nav--card-items--border: #804964;
  --tab-secondary-hover-bg: #99acbe;
  --nav-menu: #d7dce3;


  --text-1: #000000;
  --text-2: #030303;
  --text-3: #585757;
  --text-4: #b5b2b2;
  --text-5: #060606;
  --text-6: #787f8c;
  --text-7: #202124;
  --text-8: #575656;
  --text-9: #818181;
  --text-10: #eae8e8;
  --text-11: #8b8a8a;
  --text-12: #1c1c1c;
  --text-13: #0b0b0b;
  --text-14: #707070;
  --text-14: #9F9F9F;
  --text-15: #8E8E8E;
  --text-16: #9a9a9a;
  --text-17: #868585;
  --text-18: #1A1717;

  --border-1: #ebebeb;
  --border-2: #dedede;
  --border-3: #f4f4f4;
  --border-4: #ededed;
  --border-5: #707070;
  --border-6: #e0e0e0;
  --border-7: #dbdbdb;
  --border-8: #00000080;
  --border-9: #E8E8E8;
  --border-10: #d6d5d5;
  --border-11: #cbc8c8;
  --border-12: #0000004B;
  --border-13: #C6BEBE;
  --border-14: #FFFFFF;
  --border-15: #8B8686;
  --border-16: #7070703C;
  --border-17: #D6D6D6;
  --border-18: #7b7a7a;
  --border-19: #C4C3C3;

  --status-completed-dark: #2fa561;
  --status-completed-lite: #9fffc8;
  --status-rejected-dark: #f52f62;
  --status-rejected-lite: #f3d1d9;
  --status-inprogress-dark: #ffa030;
  --status-inprogress-lite: #fce1ba;
  --status-hold-dark: #be52f2;
  --status-hold-lite: #eedff2;
  --status-done-dark: #6258c4;
  --status-done-lite: #d2cff0;
  --status-inreview-dark: #11afa1;
  --status-inreview-lite: #c3fff3;
  --status-blocked-dark: #ff647c;
  --status-blocked-lite: #fbe4e8;
  --status-duplicate-dark: #6979f8;
  --status-duplicate-lite: #e5e7fa;
  --status-reopen-dark: #1c90f5;
  --status-reopen-lite: #d5e9fa;

  --borderWidth-1: 1px;
  --borderWidth-2: 2px;
  --borderStyle1: solid;

  --white: #fff;

  --link: #435eff;
  --success: #34c58f;
  --warning: #d48806;
  --error: #ff4d4d;
  --info: #e0edff;

  --shadow-1: 0 1px 4px 0 rgba(41, 45, 55, 0.16);
  --shadow-2: 0 2px 6px 0 rgba(41, 45, 55, 0.16);
  --shadow-3: 0 2px 12px 0 rgba(59, 81, 138, 0.16);
  --shadow-4: 0 2px 16px 0 rgba(41, 45, 55, 0.16);
  --shadow-5: 0 2px 16px 0 rgba(41, 45, 55, 0.16);
  --shadow-6: 0 2px 16px 0 rgba(41, 45, 55, 0.16);
  --shadow-7: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --shadow-8: 0 1px 3px 0 rgba(112, 144, 176, 0.2);
  --shadow-9: 0 13px 26px 0 rgba(0, 0, 0, 0.16);
  --shadow-10: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
  --shadow-11: 0 20px 50px 0 rgba(0, 0, 0, 0.36);
  --menu-color: #4e4e4e;
  --table-header: #E2E2E2;
  --table-body: #FDFDFD;
  --th-color: #455457;
  --td-color: #35353d;
  --table-btn: #f3f8f8;
  --table-btn-color: #425466;
  --table-btn-hover: #FDFDFD;
  --table-row-active: #E2E2E2;
  ;
  --odd-tr: #F5F5F5;
}