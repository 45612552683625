.wrapper {
  border-radius: 0.4rem;
  width: max-content;
  padding: 0.1rem 1rem;
  font-size: 1.3rem;
  background: rgba(79, 104, 173, 0.1);
  color: var(--text-9);
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
}

.link {
  color: var(--link);
}

.common {
  display: grid;
  grid-auto-flow: column;
  justify-content: left;
  gap: 1rem;
}

.title {
  display: grid;
  justify-content: left;
  grid-auto-flow: column;
  gap: 1rem;
  align-items: center;

  span {
    color: var(--text-1);
    font-weight: 600 !important;
  }
}

.message {
  padding: 2rem;
}

._Story {
  background: rgba(4, 174, 93, 0.12);
  color: var(--success);
}

._Task {
  background: rgba(67, 94, 255, 0.12);
  color: var(--link);
}

._Bug {
  background: rgba(255, 37, 76, 0.1);
  color: var(--error);
}

._Epic {
  background: rgba(48, 68, 124, 0.1);
  color: var(--text-9);
}